import axiosIns from '@/libs/axios'

export function addFile({ companyId, file }, config = {}) {
  const formData = new FormData()
  formData.append('file', file)
  return axiosIns.post(`/medialibrary/files/${companyId}`, formData, config)
}

export function deleteFile({ id }) {
  return axiosIns.delete(`/medialibrary/files/${id}`)
}

export function editFile({
  id,
  title,
  description,
  model,
  model_id,
  model_field,
  model_sort_order,
}) {
  return axiosIns.patch(`/medialibrary/files/${id}`, {
    title,
    description,
    model,
    model_id,
    model_field,
    model_sort_order,
  })
}

export default {
  deleteFile,
  editFile,
}
