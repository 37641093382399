<template>
  <b-form-group
    class="file-uploader"
    label="Изображения"
  >
    <b-alert
      v-if="!files.length && !upload_files.length"
      variant="primary"
      show
    >
      <div class="alert-body">
        Нет ниодного файла
      </div>
    </b-alert>
    <b-list-group class="mb-1">
      <draggable
        v-model="files"
        :animation="200"
        class="file-uploader__draggable"
        :class="{
          'file-uploader__draggable_not-alone': upload_files.length,
        }"
        handle=".file-uploader__move"
      >
        <b-list-group-item
          v-for="(file, index) in files"
          :key="'f' + index"
        >
          <b-row align-v="center">
            <b-col
              v-if="mode !== 'view'"
              cols="auto"
            >
              <feather-icon
                class="text-primary file-uploader__move"
                icon="MoveIcon"
              />
            </b-col>
            <b-col cols="auto">
              <div
                v-if="!file.loaded"
                class="file-uploader__preview_loader bg-light"
              >
                <b-spinner variant="primary" />
              </div>
              <img
                v-show="file.loaded"
                class="file-uploader__img"
                :src="file.path"
                @click="indexGalleryImage = index"
                @load="loadImage(file)"
              >
            </b-col>
            <b-col class="file-uploader__name-col">
              <b-form-group
                v-if="mode !== 'view'"
                label="Alt"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model.trim="file.title"
                  placeholder="Введите alt"
                />
              </b-form-group>
              <b-form-group
                v-else-if="mode === 'view' && file.title"
                label="Alt"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model.trim="file.title"
                  placeholder="-"
                  plaintext
                />
              </b-form-group>
              <b-link
                target="_blank"
                :title="file.file_name"
                class="file-uploader__name"
                @click="indexGalleryImage = index"
              >
                {{ file.file_name }}
              </b-link>
              <div class="">
                <small>
                  Размер: {{ file.file_size / 1000 }} КБ
                </small>
              </div>
            </b-col>
            <b-col
              v-if="mode !== 'view'"
              cols="auto"
              class="ml-auto"
            >
              <b-button
                variant="flat-primary"
                class="btn-icon"
                size="sm"
                @click="deleteFile(file)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-col>
          </b-row>

        </b-list-group-item>
      </draggable>

      <b-list-group-item
        v-for="(file, index) in upload_files"
        :key="'uf' + index"
        class=""
      >
        <b-row align-v="center">
          <b-col
            class="file-uploader__name-col"
            cols="12"
          >
            <div
              class="file-uploader__name"
              :title="file.file_name"
            >
              {{ file.file_name }}
            </div>
            <div class="">
              <small>
                Размер: {{ file.file_size / 1000 }} КБ
              </small>
            </div>
          </b-col>
          <b-col
            v-if="file.error"
            cols="12 mt-1"
          >
            <small class="text-danger">{{ file.error }}</small>
          </b-col>
          <b-col
            v-else
            cols="12 mt-1"
          >
            <b-progress
              v-model="file.progress"
              max="100"
              show-value
              animated
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <input
      id="files"
      ref="files"
      type="file"
      multiple
      :accept="accept"
      @change="handleFilesUpload()"
    >
    <!-- <div
      ref="fileSelect"
      class="file-uploader__select-block"
      @click="changeFiles()"
    >
      Выберите или перетащите файлы
    </div>-->
    <b-button
      v-if="mode !== 'view'"
      ref="fileSelect"
      variant="outline-primary"
      class="w-100"
      @click="changeFiles()"
    >
      Выберите файлы
    </b-button>
    <vue-gallery
      :id="'g' + uid"
      :images="imagesForGallery"
      :index="indexGalleryImage"
      @close="indexGalleryImage = null"
    />
  </b-form-group>
</template>

<script>
// - режим просмотра
import {
  BListGroup, BListGroupItem, BLink, BRow, BCol, BButton, BAlert, BFormGroup, BProgress,
  BFormInput, BSpinner,
} from 'bootstrap-vue'
import { get } from 'lodash'
import draggable from 'vuedraggable'
import VueGallery from 'vue-gallery'
import { addFile, deleteFile } from '@/services/main-api/medialibrary/files'

export default {
  name: 'ImageUploader',
  initialDownloadComplete: false,
  components: {
    BListGroup,
    BListGroupItem,
    BLink,
    BRow,
    BCol,
    BButton,
    BAlert,
    BFormGroup,
    BProgress,
    BFormInput,
    draggable,
    VueGallery,
    BSpinner,
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: '.jpg,.jpeg,.png',
    },
    mode: {
      type: String,
      default: '', // view
    },
  },
  data() {
    return {
      files: [],
      upload_files: [],
      last_index: 0,
      dragAndDropCapable: false,
      // eslint-disable-next-line no-underscore-dangle
      uid: this._uid,
      indexGalleryImage: null,
    }
  },
  computed: {
    imagesForGallery() {
      return this.files.map(i => i.path)
    },
  },
  watch: {
    files(newValue) {
      this.$emit('input', newValue)
    },
    value(newValue) {
      if (!this.$options.initialDownloadComplete) {
        this.files = newValue.map(i => ({
          ...i,
          loaded: false,
        }))
        this.$options.initialDownloadComplete = true
      }
    },
  },
  mounted() {
    // this.dragAndDropCapable = this.determineDragAndDropCapable()
    //
    // if (this.dragAndDropCapable) {
    //   ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(evt => {
    //     this.$refs.fileSelect.addEventListener(evt, this.stopEventForListener, false)
    //   })
    //   this.$refs.fileSelect.addEventListener('drop', this.uploadFilesForListener)
    // }
  },
  beforeDestroy() {
    // if (this.dragAndDropCapable) {
    //   ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(evt => {
    //     this.$refs.fileSelect.removeEventListener(evt, this.stopEventForListener, false)
    //   })
    //   this.$refs.fileSelect.removeEventListener('drop', this.uploadFilesForListener)
    // }
  },
  methods: {
    async handleFilesUpload() {
      const uploadedFiles = this.$refs.files.files
      for (let i = 0; i < uploadedFiles.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        this.uploadFile(uploadedFiles[i])
      }
      this.$refs.files.value = ''
    },
    changeFiles() {
      this.$refs.files.click()
    },
    async uploadFile(file) {
      // eslint-disable-next-line no-plusplus
      const index = this.last_index++
      const fileAndProgress = {
        file,
        progress: 0,
        id: index,
        file_name: file.name,
        file_size: file.size,
        content_type: file.type,
        error: null,
      }
      console.log(fileAndProgress)
      this.upload_files.push(fileAndProgress)
      try {
        const res = await addFile({
          companyId: this.companyId,
          file,
        }, {
          // eslint-disable-next-line func-names
          onUploadProgress: progressEvent => {
            fileAndProgress.progress = parseInt((progressEvent.loaded * 100) / progressEvent.total)
          },
        })

        this.files.push({ ...res.data.data, loaded: false })
        const deletedIndex = this.upload_files.findIndex(i => i.id === index)
        this.upload_files.splice(deletedIndex, 1)
      } catch (e) {
        fileAndProgress.error = get(e, 'response.data.errors.file[0]')
      }
    },
    submitFile() {
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div')
      return (('draggable' in div)
          || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window
        && 'FileReader' in window
    },
    stopEventForListener(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    uploadFilesForListener(e) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        this.uploadFile(e.dataTransfer.files[i])
      }
    },
    async deleteFile(file) {
      const resButton = await this.$swal({
        title: 'Вы уверены?',
        text: `Файл ${file.file_name} будет удален`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да, удалить!',
        cancelButtonText: 'Отмена',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (resButton.value) {
        try {
          const indexDeleteFile = this.files.findIndex(i => i.id === file.id)
          this.files.splice(indexDeleteFile, 1)
          this.$swal({
            icon: 'success',
            title: 'Удалено!',
            text: 'Файл был удален.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          deleteFile({
            id: file.id,
          })
        } catch (e) {
          console.log(e)
        }
      }
    },
    async loadImage(file) {
      console.log(file)
      await this.$nextTick()
      const index = this.files.findIndex(i => i.id === file.id)
      this.files[index].loaded = true
    },
  },
}
</script>

<style scoped lang="scss">
@import "../@core/scss/base/bootstrap-extended/include";

  .file-uploader{
    input[type=file]{
      display: none;
    }
    &__select-block{
      background: #ddd;
      padding: 30px 10px;
      border-radius: 10px;
      text-align: center;
      cursor: pointer;
    }
    &__name{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 100%;
    }
    &__name-col{
      min-width: 0;
    }

    &__img{
      max-width: 100%;
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: center center;
      cursor: pointer;
      border-radius: 0.358rem;
      @include media-breakpoint-down(xs){
        width: 50px;
        height: 50px;
      }
    }

    &__preview_loader{
      max-width: 100%;
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.358rem;
    }

    &__move{
      cursor: move;
    }
    &__draggable{
      border-radius: inherit;
      &_not-alone{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: -1px;
      }
    }
  }
</style>
